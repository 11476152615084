<template>
  <div class="page">
    <div class="family-box">
      <div class="family-item" v-for="(item, idx) in familyInfo" :key="item.group_name">
        <div class="title flexcenter">
          <div class="left">{{item.group_name}}</div>
          <i class="iconfont iconxiangxia"></i>
        </div>
        <template v-if="item.cart_goods">
          <product1 class="p1"
                    v-for="(cart) in item.cart_goods"
                    :key="cart.cart_id"
                    :number.sync="cart.goods_number"
                    :goods="cart"
          ></product1>
        </template>
        <div class="zanwu" v-else>暂无商品</div>
        
        <div class="heji-box flexcenter">
          <div class="left">已选{{item.total_number}}件，合计：<span class="red">￥{{item.total_amount}}</span></div>
          <div @click="goAddGoods(item.group_name)" class="right">添加</div>
        </div>
      </div>
      
      <div class="bottom flexcenter">
        <div class="yixuan">共 {{familyInfo.length}} 个家庭</div>
        <div class="cashier flexcenter">
          <div class="heji">合计: </div>
          <div class="price">￥{{allAmount}}</div>
          <div @click="goCheckout" class="buy">立即支付</div>
        </div>
      </div>
    </div>
  
    <div class="empty-box flexcenter">
      <div @click="createFamily">
        <div class="btn-add flexcenter">
          <i class="iconfont iconjia"></i>
        </div>
        <span class="cjjt">创建家庭</span>
      </div>
    </div>
  </div>
</template>

<script>
  import product1 from 'components/product1'
  
  export default {
    name: 'daoyou-family',
    data() {
      return {
        familyInfo:[],
        allAmount:0
      }
    },
    components: {
      product1,
    },
    created() {
      this.getFamilyCart()
    },
    methods: {
      /**
       * 创建一个家庭
       **/
      createFamily:function(){
        let familyInfo = this.familyInfo;
        let num = familyInfo.length+1;
        console.log(num);
        familyInfo.push({
          group_name:num+"号家庭",
          cart:[],
          totalAdmount:0
        })

        this.familyInfo = familyInfo
      },
      /**去添加商品 */
      goAddGoods:function(family){
        this.$router.push({
          name: 'daoyou-normal',
          query: {
            family
          }
        })
      },
      /**
       * 获取家庭购物车数据
       */
      getFamilyCart:function(){
        let that = this;
        let allAmount = 0;
        this.post(this.API.getFamilyCart,{})
          .then(response => {
            if(response.data) {
              response.data.forEach(function(goods,k){
                allAmount = allAmount + goods.total_amount;
              })
  
              this.familyInfo = response.data
              this.allAmount = allAmount
            }
          })
      },
      refeshPage:function(){
        this.getFamilyCart();
      },
      goCheckout:function(){
        this.$router.push({
          name: 'spjs',
          query: {
            family: 1,
          }
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }
  .empty-box {
    justify-content: center;
    padding:50px 0px 136px 0px;
    text-align: center;
  }
  .btn-add {
    margin-top: -150px;
    justify-content: center;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    background-image: linear-gradient(-54deg, #FF9D10 0%, #FCCE09 96%);
  }
  .btn-add .iconfont {
    font-size: 80px;
    color: #FFFFFF;
  }
  .cjjt {
    margin-top: 24px;
    font-size: 28px;
    color: #191919;
  }

  .detail-box:last-child {
    border: 0!important;
  }

  .family-box {
    padding-bottom: 120px;
  }
  .family-box .family-item {
    margin: 30px 24px;
    background-color: #FFFFFF;
    border-radius: 6px;
  }
  .family-box .family-item .zanwu {
    height: 168px;
    line-height: 168px;
    font-size: 28px;
    color: #777777;
    text-align: center;
  }
  .family-box .family-item .title {
    justify-content: space-between;
    height: 88px;
    box-sizing: border-box;
    padding: 0 24px;
    border-bottom: 1px solid #EEEEEE;
    font-size: 28px;
    font-weight: bold;
  }
  .family-box .family-item .heji-box {
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 24px;
    height: 100px;
    font-size: 28px;
    border-top: 1px solid #EEEEEE;
  }
  .family-box .family-item .heji-box .left {
    color: #777777;
  }
  .family-box .family-item .heji-box .left .red {
    font-weight: bold;
    color: #FF3B33;
  }
  .family-box .family-item .heji-box .right {
    width: 128px;
    height: 72px;
    line-height: 72px;
    border: 1px solid #FCC601;
    border-radius: 36px;
    text-align: center;
    color: #FCC601;
    font-size: 32px;
    font-weight: bold;
  }

  .bottom {
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 0 30px;
    height: 110px;
    background-color: #FFFFFF;
  }
  .bottom .yixuan {
    color: #191919;
    font-size: 24px;
  }
  .bottom .heji {
    font-size: 24px;
    color: #191919;
  }
  .bottom .price {
    margin-right: 40px;
    font-size: 30px;
    color: #F84F41;
  }
  .bottom .buy {
    width: 192px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
    background-color: #FCC601;
    text-align: center;
    font-size: 30px;
    color: #FFFFFF;
  }

</style>
